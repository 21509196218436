import React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import AboutHeader from "../components/AboutHeader";
import AboutContent from "../components/AboutContent";
import AboutCTA from "../components/AboutCTA";

const AboutPage = () => (
  <>
    <SEO
      title={"About"}
      description={"We support your digital transformation and help you unleash the potential of every team leveraging Atlassian products."}
    />
    <Layout>
      <AboutHeader />
      <AboutContent />
      <AboutCTA />
    </Layout>
  </>
)

export default AboutPage

