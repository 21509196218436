import React from "react";
import { Link } from "gatsby";

const IndexCTA = () => {
  return (
    <div className="bg-gradient-to-r from-teal-500 to-cyan-600">
      <div className="max-w-2xl mx-auto py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">What's Next?</span>
          <span className="block text-cyan-900">Experience the Difference.</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-cyan-100">Get a head start on your teams and Atlassian products.</p>
        <Link to="/contact/" className="mt-8 w-full border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base font-medium sm:w-auto bg-cyan-50 text-cyan-700 hover:bg-cyan-100 hover:text-cyan-900">
          Get in touch
        </Link>
      </div>
    </div>
  )
}

export default IndexCTA
